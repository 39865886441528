/* istanbul ignore file */

import React from 'react';

import { formatTime } from '../../common/helpers';

const entry = ({ details }, { connectorList }) => {
  const { connector } = details;

  const { name, bound_domain } =
    connectorList?.find(({ id }) => id === connector) || {};

  return {
    icon: 'circle-plus',
    iconText: 'Connector added',
    text: 'Connector added',
    description: name || bound_domain || 'Connector details are pending...',
  };
};

const detail = ({ action_type, details }, { connectorList }) => {
  const { connector, created_by, created_on } = details;

  const { name, bound_domain } =
    connectorList?.find(({ id }) => id === connector) || {};

  return (
    <div className={`activity-entry-details ${action_type}`}>
      <p>Created on: {formatTime(created_on)}</p>
      <p>Created by: {created_by}</p>
      <p>
        Connector name:{' '}
        {name || bound_domain || 'Connector details are pending...'}
      </p>
    </div>
  );
};

export default { entry, detail };
