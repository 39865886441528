import { timezones } from 'app/common/constants';
import {
  DEFAULT_DELAY_OPTION,
  DEFAULT_TIME_IDX,
  TIME_OPTIONS,
  VERSION_ENFORCEMENT_OPTIONS,
} from 'features/library-items/common/updates-card/updates-card-constants';

const INITIAL_MODEL = {
  id: '',
  name: 'tvOS Release',
  instanceName: '',
  icon: '',
  isActive: true,
  osRequirements: '',
  blueprints: [],
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,
  publisher: 'Apple, Inc.',
  updates: {
    versionEnforcement: VERSION_ENFORCEMENT_OPTIONS[0],
    minimumVersion: null,
    enforcementDelay: DEFAULT_DELAY_OPTION,
    enforcementDeadline: null,
    enforcementTime: TIME_OPTIONS[DEFAULT_TIME_IDX],
    enforcementTimezone: timezones[0],
  },
  supportsRules: true,
};

export { INITIAL_MODEL };
