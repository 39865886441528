import { Button } from '@kandji-inc/bumblebee';
import { forgotPassword } from 'app/_actions/app';
import { ForgotPasswordForm } from 'app/components/settings/ForgotPasswordForm';
import React, { Component } from 'react';
import { Container } from 'reactstrap';
import history from '../app/router/history';

export class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getResponse: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  onCancelClick = () => {
    history.push('/signin');
  };

  handleSubmit(form) {
    forgotPassword(form).finally(() => this.setState({ getResponse: true }));
  }

  render() {
    const { getResponse } = this.state;
    return (
      <Container>
        <div className="white-container d-flex flew-flow-column align-items-center justify-content-start reset-password-container">
          {!getResponse && (
            <div style={{ textAlign: 'center' }}>
              <div className="reset-password-title">
                Enter the Email associated with your account.
              </div>
              <ForgotPasswordForm onSubmit={this.handleSubmit} />
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button kind="outline" onClick={this.onCancelClick}>
                  Cancel
                </Button>
              </div>
            </div>
          )}
          {getResponse && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div className="reset-password-title">
                Email was sent. Check your mailbox.
              </div>
              <div>
                <Button onClick={this.onCancelClick}>Back to login page</Button>
              </div>
            </div>
          )}
        </div>
      </Container>
    );
  }
}

export default ForgotPassword;
