/* istanbul ignore file */
import { i18n } from 'src/i18n';
import { INT_TYPES } from '../../../../constants';

import type { AdcsInputs, AdcsState } from '../../types/adcs.types';
// Types
import type { IntegratorViewConfig } from '../../types/integrator.types';

// Icons
import adcsLogo from '../../../../generic-cards-view/assets/adcs.svg';
import downloadSingleIcon from '../../../adcs/assets/download-single.svg';
import pendingConnectionIcon from '../../../adcs/assets/pending-set-icon.svg';

// Helpers
import {
  downloadStatusOptions,
  downloadTickets,
  handleNextDownload,
  handleNextTextDownload,
  overviewHandleClose,
  overviewHandleRedownload,
  сonnectionTickets,
} from './helper';

// Config
const configOverview: IntegratorViewConfig<AdcsInputs, AdcsState, object> = {
  type: INT_TYPES.adcs,
  name: 'adcs',
  logo: adcsLogo,
  openDialog: true,
  pages: [
    {
      name: 'adcsDownloadAndCreate',
      layout: 'one-column-info',
      logo: downloadSingleIcon,
      header: {
        get title() {
          return i18n.t('Download AD CS connector');
        },
        get description() {
          return i18n.t(
            'Once the download is complete, you will need to install the connector on your Windows server machine.',
          );
        },
      },
      tickets: downloadTickets,
      // footer
      get footerProgressText() {
        return `{i18n.t('Kandji AD CS connector is downloading')}...`;
      },
      footerStatusOptions: downloadStatusOptions,
      nextButtonText: handleNextTextDownload,
      get cancelButtonText() {
        return i18n.t('Cancel');
      },
      handleClose: overviewHandleClose,
      handleNext: handleNextDownload,
    },
    {
      name: 'adcsRedownload',
      layout: 'one-column-info',
      logo: downloadSingleIcon,
      header: {
        get title() {
          return i18n.t('Download AD CS connector');
        },
        get description() {
          return i18n.t(
            'Once the download is complete, you will need to install the connector on your Windows server machine.',
          );
        },
      },
      tickets: downloadTickets,
      // footer
      get footerProgressText() {
        return `{i18n.t('Kandji AD CS connector is downloading')}...`;
      },
      footerStatusOptions: downloadStatusOptions,
      nextButtonText: handleNextTextDownload,
      get cancelButtonText() {
        return i18n.t('Cancel');
      },
      handleClose: overviewHandleClose,
      handleNext: overviewHandleRedownload,
    },
    {
      name: 'adcsInstructions',
      layout: 'one-column-info',
      logo: pendingConnectionIcon,
      header: {
        get title() {
          return `{i18n.t('Connection pending')}...`;
        },
        get description() {
          return i18n.t(
            'Perform the following steps below to finalize the integration with Active Directory Certificate Services',
          );
        },
      },
      tickets: сonnectionTickets,
      // footer
      showCancelButton: false,
      get backButtonText() {
        return i18n.t('Redownload connector');
      },
      get nextButtonText() {
        return i18n.t('Close');
      },
      handleNext: overviewHandleClose,
    },
  ],
};

export { configOverview };
