import validator from 'validator';

const requiredValidator = (val) => ({
  message: 'Required',
  invalid: () => !val.length,
  trigger: ['onBlur'],
});

const matchingValidator = (val, email) => ({
  message: 'Emails do not match',
  invalid: () => val !== email,
  trigger: ['onBlur'],
});

const emailValidator = (val) => ({
  message: 'Invalid email address',
  invalid: () => !validator.isEmail(val),
  trigger: ['onBlur'],
});

export { requiredValidator, matchingValidator, emailValidator };
