import React, { useState, useEffect } from 'react';

import {
  Banner,
  Button,
  Flex,
  modal as Modal,
  Select,
} from '@kandji-inc/bumblebee';

import { i18n } from 'i18n';

import { Controls } from 'features/library-items/template';

import { useAdvanced, useAuthority, useIs, useModal } from '../../hooks';

import library from '../../library';

import { findOption, getOptionsFromObject } from '../../util';

const ModalCreateLibraryItem = () => {
  const { gotoCreateLibraryItem } = useAdvanced();

  const { clearAuthority } = useAuthority();

  const { isLoading, isWorking } = useIs();
  const { modal, closeModal } = useModal();

  const isOpen = !!modal['create-library-item'];

  const [identifier, setIdentifier] = useState('');

  const optionsLibraryItems = getOptionsFromObject(library).map(
    ({ value, data: { label, icon } }) => ({
      value,
      label,
      icon,
    }),
  );

  const valueLibraryItem = findOption(identifier, optionsLibraryItems);

  /* istanbul ignore next */
  const reset = () => {
    clearAuthority();
    setIdentifier('');
  };

  /* istanbul ignore next */
  const onCancel = () => {
    closeModal(reset);
  };

  /* istanbul ignore next */
  const onCreate = () => {
    closeModal(() => gotoCreateLibraryItem(identifier));
  };

  /* istanbul ignore next */
  useEffect(
    () => () => {
      reset();
    },
    [],
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={
        // onClose is required by Modal but not needed by this component
        /* istanbul ignore next */
        () => {}
      }
      withoutCloseButton
      animationDuration={250}
      parts={{
        header: {
          text: i18n.t('Specify Library Item type'),
        },
        content: {
          children: (
            <div style={{ minHeight: 130 }}>
              <p className="b-txt b-mb2" style={{ width: 496 }}>
                {i18n.t(
                  'Select which Library Item you would like to create using this AD CS server.',
                )}
              </p>

              <Controls className="k-adcs-library-item-select b-mb2">
                <Select
                  kind="icon"
                  placeholder={i18n.t('Select Library Item type')}
                  options={optionsLibraryItems}
                  value={valueLibraryItem}
                  onChange={
                    /* istanbul ignore next */
                    (v) => setIdentifier(v.value)
                  }
                />
              </Controls>

              {
                /* istanbul ignore next */
                identifier && (
                  <Banner
                    className="b-mt2 b-mb2"
                    theme="info"
                    kind="block"
                    style={{ width: 496 }}
                  >
                    <p>
                      {i18n.t(
                        'You are about to navigate away from Active Directory Certificate Services. If you have any unfinished work, please click on cancel to return to the current page.',
                      )}
                    </p>
                  </Banner>
                )
              }
            </div>
          ),
        },
        footer: {
          children: (
            <Flex>
              <Button
                kind="outline"
                onClick={onCancel}
                isDisabled={isLoading || isWorking}
              >
                {i18n.t('Cancel')}
              </Button>

              <Button
                className="l-ml"
                onClick={
                  /* istanbul ignore next */
                  identifier ? onCreate : () => {}
                }
                isDisabled={isLoading || isWorking || !identifier}
                isProgress={isWorking}
              >
                {i18n.t('Create Library Item')}
              </Button>
            </Flex>
          ),
        },
      }}
    />
  );
};

export default ModalCreateLibraryItem;
