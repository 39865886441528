/* istanbul ignore file */
import { useMutation } from '@tanstack/react-query';
import { useRef } from 'react';
import { vulnerabilityApi } from 'src/app/api/base';
import type { CVEFeedback, Vulnerability } from '../vulnerability.types';

const useSendMatchFeedback = () => {
  const lastCallPromise = useRef(Promise.resolve());

  const {
    mutateAsync: sendMatchFeedbackMutation,
    isPending,
    isSuccess,
    isError,
  } = useMutation({
    mutationFn: (args: {
      cveId: Vulnerability['cve_id'];
      feedback: CVEFeedback;
    }) => vulnerabilityApi(`/feedback/${args.cveId}`).post(args.feedback),
  });

  const sendMatchFeedback = async (args) => {
    // Chain calls using the lastCallPromise ref, ensuring a 3-second delay
    lastCallPromise.current = lastCallPromise.current.then(async () => {
      await sendMatchFeedbackMutation(args);
      // Wait 3 seconds before allowing the next call to proceed as to not hit the rate limit
      return new Promise((resolve) => setTimeout(resolve, 3000));
    });

    // Await to ensure the function resolves after the entire chain is completed
    await lastCallPromise.current;
  };

  return { sendMatchFeedback, isPending, isSuccess, isError };
};

export { useSendMatchFeedback };
