export enum Severity {
  undefined = 'Undefined',
  none = 'None',
  low = 'Low',
  medium = 'Medium',
  high = 'High',
  critical = 'Critical',
}

export type Vulnerability = {
  cve_id: string;
  cve_link: string;
  cve_published_at: string;
  cve_modified_at: string;
  description: string;
  app_name: string;
  cvss_score: string;
  severity: Severity;

  // Applicable when a Vulnerability is associated with entire fleet
  first_detection_date?: string;
  latest_detection_date?: string;
  device_count?: string;
  versions?: string[];

  // Applicable when a Vulnerability is associated with a specific device
  detection_datetime?: string;
  app_version?: string;
  app_path?: string;
};

export type AppSummary = {
  detection_date: string;
  path: string;
  version: string;
  name: string;
};

export type VulnerableDevice = {
  app_summary: AppSummary[];
  assigned_user: string;
  blueprint_name: string;
  blueprint_id: string;
  device_id: string;
  first_detected: string;
  last_detected: string;
  model: string;
  name: string;
  no_of_installs: number;
  os_version: string;
  serial_number: string;
};

export type VulnerableDevicePagination = {
  page: number;
  size: number;
};

export type VulnerabilityDevices = {
  devices: VulnerableDevice[];
  totalDevices: number;
  devicePagination: VulnerableDevicePagination;
};

export type CVEFeedback = {
  issue: string;
  details?: string;
};
