import { timezones } from 'app/common/constants';
import {
  DEFAULT_DELAY_OPTION,
  DEFAULT_TIME_IDX,
  TIME_OPTIONS,
  VERSION_ENFORCEMENT_OPTIONS,
} from 'features/library-items/common/updates-card/updates-card-constants';
import MacosReleasesService from 'features/library-items/items/macos-releases/service/macos-releases-service';

const INITIAL_MODEL = {
  id: '',
  name: 'macOS Release',
  instanceName: '',
  icon: '',
  isActive: true,
  osRequirements: '',
  blueprints: [],
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,
  updates: {
    versionEnforcement: VERSION_ENFORCEMENT_OPTIONS[0],
    minimumVersion: null,
    enforcementDelay: DEFAULT_DELAY_OPTION,
    enforcementDeadline: null,
    enforcementTime: TIME_OPTIONS[DEFAULT_TIME_IDX],
    enforcementTimezone: timezones[0],
    versions: {},
  },
  installation: {
    type: MacosReleasesService.installationTypes.CONTINUOUSLY_ENFORCE,
  },
  selfService: {
    isEnabled: false, // false => item.data.self_service_data === undefined
    category: '', // item.data.self_service_data.category
    isRecommended: false, // item.data.self_service_data.isRecommended
    displayName: null, // item.data.self_service_data.displayName
    shortDescription: null, // item.data.self_service_data.description
    longDescription: null, // item.data.self_service_data.bodyText
    isRequireRead: false, // item.data.self_service_data.mustViewAgreement
    iconUrl: null, // item.data.self_service_data.iconUrl
    iconFile: null,
    installBtnText: null,
    size: '',
    displayInfoBtn: false,
    infoBtnText: '',
    infoBtnUrl: '',
  },
  supportsRules: true,
};

export { INITIAL_MODEL };
