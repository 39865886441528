/* istanbul ignore file */

import React from 'react';

import { formatTime } from '../../common/helpers';

const entry = ({ details }, { connectorList }) => {
  const { connector } = details;

  const { name, bound_domain } =
    connectorList?.find(({ id }) => id === connector) || {};

  return {
    icon: 'trash-can',
    iconText: 'Connector deleted',
    text: 'Connector deleted',
    description: name || bound_domain || 'Connector details are pending...',
  };
};

const detail = ({ action_type, details }, { connectorList }) => {
  const { connector, deleted_by, deleted_on } = details;

  const { name, bound_domain } =
    connectorList?.find(({ id }) => id === connector) || {};

  return (
    <div className={`activity-entry-details ${action_type}`}>
      <p>Deleted on: {formatTime(deleted_on)}</p>
      <p>Deleted by: {deleted_by}</p>
      <p>
        Connector name:{' '}
        {name || bound_domain || 'Connector details are pending...'}
      </p>
    </div>
  );
};

export default { entry, detail };
