import {
  Checkbox,
  Slider,
  TextInput,
  getMinMaxValidator,
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import React, { useEffect, useState } from 'react';

import { Setting } from 'features/library-items/template';

const MIN_DAYS = 1;
const MAX_DAYS = 365;
const MARKERS = [MIN_DAYS, 120, 240, MAX_DAYS];

const useLastValidNumber = (strNum, { init, min, max }) => {
  const parse = (val) => {
    let num = parseInt(val, 10);
    if (Number.isNaN(num)) {
      return null;
    }
    if (min != null) {
      num = Math.max(min, num);
    }
    if (max != null) {
      num = Math.min(max, num);
    }

    return num;
  };
  const [validNum, setValidNum] = useState(() => parse(strNum) ?? init);

  useEffect(() => {
    const num = parse(strNum);

    setValidNum((p) => num ?? p);
  }, [strNum]);

  return validNum;
};
const runValidator = (validator, value) =>
  validator(value).some(({ invalid }) => invalid());

const AutoPassword = (props) => {
  const { setting, update, isDisabled, validationDep } = props;
  const fieldsToValidate = ['rotateDays'];
  const { refs, onInvalidate } = useInputsValidators(fieldsToValidate, update);
  useRemoveValidationOnUnmount(fieldsToValidate, update);
  const rotateDaysValidator = getMinMaxValidator({
    trigger: ['onBlur', validationDep],
    min: MIN_DAYS,
    max: MAX_DAYS,
  });
  useEffect(() => {
    if (
      setting.isRotatePassword &&
      runValidator(rotateDaysValidator, setting.rotateDays)
    ) {
      onInvalidate('rotateDays')(true);
    } else {
      onInvalidate('rotateDays')(false);
    }
  }, [setting.rotateDays, setting.isRotatePassword]);

  const sliderValue = useLastValidNumber(setting.rotateDays, {
    init: MIN_DAYS,
    min: MIN_DAYS,
    max: MAX_DAYS,
  });

  return (
    <>
      <Checkbox
        label="Automatically rotate password"
        checked={setting.isRotatePassword}
        onChange={() => update('isRotatePassword', !setting.isRotatePassword)}
        isDisabled={isDisabled}
      />
      {setting.isRotatePassword && (
        <Setting.SecondaryControls className="b-mt3">
          <div className="k-rp-mc__rotate-controls">
            <div className="k-rp-mc__day-row">
              <p ref={refs.rotateDays} className="b-txt">
                Rotate password every
              </p>
              <TextInput
                value={setting.rotateDays}
                onChange={(e) => update('rotateDays', e.target.value)}
                type="number"
                className="k-rp-mc__day-row-input"
                superCompact
                disabled={isDisabled}
                onInvalidate={onInvalidate('rotateDays')}
                validator={rotateDaysValidator}
              />
              <p className="b-txt"> days </p>
            </div>
            <Slider
              onChange={(v) => update('rotateDays', `${v}`)}
              value={sliderValue}
              min={MIN_DAYS}
              max={MAX_DAYS}
              markedValues={MARKERS}
              disabled={isDisabled}
            />
          </div>
        </Setting.SecondaryControls>
      )}
    </>
  );
};

export default AutoPassword;
