import * as React from 'react';
import type { IpaAppModel } from '../in-house-apps.types';

export function validateFileInput(
  model: IpaAppModel,
  setIsSaveEnabled: (enabled: boolean) => void,
) {
  if (model.installDetails.ipaFile.file == null) {
    setIsSaveEnabled(false);
  }
}

/* istanbul ignore next */
export function useValidateFileInput(
  model: IpaAppModel,
  setIsSaveEnabled: (enabled: boolean) => void,
) {
  React.useEffect(
    () => validateFileInput(model, setIsSaveEnabled),
    [model, setIsSaveEnabled],
  );
}
