/* istanbul ignore file */
import { Uploader, useInputsValidators } from '@kandji-inc/bumblebee';
import { i18n } from 'i18n';
import { useMemo, useState } from 'react';
import IPAFileIcon from '../assets/ipa-file-icon.webp';
import {
  IpaUploadStatus,
  getIPAS3Data,
  getIpaUploadStatus,
  uploadIpaApp,
} from '../in-house-apps-actions';
import type { IpaFile } from '../in-house-apps.types';
import { currentFileFromIpaFile } from '../utils/currentFileFromIpaFile';
import {
  IPAUploadInstructions,
  IPAUploadRenderInfo,
} from './install-uploader-components';

const IPA_MAX_FILE_SIZE = 4e9;
const IPA_ALLOWED_TYPES = ['.ipa'];
const IPA_VALIDATION_FIELDS = ['ipaFile'];

const IPA_FILE_ICONS = (icon: string) => ({
  '.ipa': <img src={icon != null ? icon : IPAFileIcon} alt="IPA App File" />,
});

const IPA_EMPTY_FILE = {
  file: null,
  fileName: null,
  fileSize: null,
  appId: null,
  appVersion: null,
  sha256: null,
  appIcon: null,
};

interface IPAUploaderProps {
  isDisabled: boolean;
  isSubmitted: boolean;
  ipaFile: IpaFile;
  setShouldUpdateFile: React.Dispatch<React.SetStateAction<boolean>>;
  updateIpaFile: (updateBody: Partial<IpaFile>) => any;
  onValidate: (cb: any) => (isValid: any) => any;
}

export function IPAUploader({
  isDisabled,
  ipaFile,
  updateIpaFile,
  onValidate,
  isSubmitted,
  setShouldUpdateFile,
}: IPAUploaderProps) {
  const { file, appIcon } = ipaFile;
  const [uploadCancelFn, setUploadCancelFn] = useState(() => () => {});
  const [apiValidationError, setApiValidationError] = React.useState<
    null | string
  >(null);
  const setIsSaveEnabled = onValidate((isValid: boolean) => isValid);
  const currentFile = useMemo(() => currentFileFromIpaFile(ipaFile), [ipaFile]);

  const { onInvalidate, invalidations } = useInputsValidators(
    IPA_VALIDATION_FIELDS,
    updateIpaFile,
  );

  const validationError = (isSubmitted && invalidations[0]) || '';

  async function handleOnUpload(file, updateProgress) {
    setApiValidationError(null);

    const ipaS3Data = await getIPAS3Data(file.name);

    setIsSaveEnabled(false);

    const { cancel: cancelUpload, upload: uploadFile } = uploadIpaApp(
      file,
      updateProgress,
      ipaS3Data,
    );

    updateIpaFile({
      file: ipaS3Data.post_data.key,
      id: ipaS3Data.id,
      fileName: file.name,
      fileSize: file.size,
      appIcon: null,
    });

    setUploadCancelFn(() => () => cancelUpload);

    return uploadFile;
  }

  function handleOnUploaded(file) {
    setIsSaveEnabled(true);
    updateIpaFile({
      fileName: file.name,
      fileSize: file.size,
    });
  }

  async function handleOnValidate(
    _,
    { getUploadCurrentlyCancelled, CancelError },
  ) {
    const [isUploadCancelled, getUploadPrevCancelled] =
      getUploadCurrentlyCancelled({
        isTrackAlreadyCancelled: true,
      });

    if (isUploadCancelled || file == null) {
      const cancelError = new CancelError(
        i18n.t('Upload cancelled during validation'),
      );
      throw new Error(cancelError);
    }

    const data = await getIpaUploadStatus(ipaFile.id);

    const { sha256, metadata, status, reason } = data;

    if (status !== IpaUploadStatus.VALIDATED) {
      setApiValidationError(reason);
      return;
    }

    const isAlreadyCancelled = getUploadPrevCancelled();

    if (getUploadCurrentlyCancelled() || isAlreadyCancelled) {
      const cancelError = new CancelError(
        i18n.t('Upload cancelled after validation'),
      );

      throw new Error(cancelError);
    }

    setShouldUpdateFile(true);
    updateIpaFile({
      appId: metadata.app_identifier,
      appIcon: metadata.app_icon,
      ipadIcon: metadata.ipad_icon,
      appVersion: metadata.app_version,
      appName: metadata.app_name,
      fileName: metadata.app_name,
      fileSize: metadata.file_size,
      sha256,
    });

    return sha256;
  }

  function handleOnError(error, { type, errorTypes }) {
    setIsSaveEnabled(true);
    if (type !== errorTypes.fileSelectError) {
      onInvalidate(0)(error);
    }
  }

  function handleOnDelete() {
    setApiValidationError(null);
    setUploadCancelFn(null);
    updateIpaFile(IPA_EMPTY_FILE);
  }

  return (
    <Uploader
      className="b-mb2"
      isDisabled={isDisabled}
      maxFileSize={IPA_MAX_FILE_SIZE}
      allowedTypes={IPA_ALLOWED_TYPES}
      withError={
        apiValidationError != null ? apiValidationError : validationError
      }
      fileIcons={IPA_FILE_ICONS(appIcon)}
      onUpload={handleOnUpload}
      onUploaded={handleOnUploaded}
      onError={handleOnError}
      onValidate={handleOnValidate}
      onCancel={uploadCancelFn}
      onDelete={handleOnDelete}
      forceWithFile={currentFile}
      uploadInstructions={IPAUploadInstructions()}
      renderInfo={() => (
        <IPAUploadRenderInfo isDisabled={isDisabled} ipaFile={ipaFile} />
      )}
    />
  );
}
