import { updateSetting as update } from '@kandji-inc/bumblebee';
import React, { useCallback } from 'react';

import useUniqValue from 'components/common/hooks/use-uniq-value/use-uniq-value';
import NewStatusTab from 'features/library-items/common/new-status-tab/new-status-tab';
import { useBlueprintConflicts } from '../../common/blueprint-conflicts';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import config from '../../library/library-item-configurations/items/profile';
import ActivityTab from './activity-tab';
import initial from './initial-state';
import LegacyCard from './legacy-card';
import ManageCard from './manage-card';
import { recoveryPasswordService } from './service/recovery-password-service';
import { transformFromApi, transformToApi } from './service/transformers';
import useRecoveryPasswordService from './service/use-recovery-password-service';

const RecoveryPasswordPage = (props) => {
  // we can't put this outside because of recursive imports
  const itemConfig = config['Recovery Password'];

  const { model, setModel, pageState } = props;
  const [validationDep, triggerValidation] = useUniqValue();
  const updateManage = useCallback(update('manage', setModel), []);
  const updateLegacy = useCallback(update('legacy', setModel), []);

  return (
    <LibraryItemPage
      {...props}
      type={itemConfig.type}
      defaultIcon={itemConfig.icon}
      identifier={itemConfig.identifier}
      crumb={model.name || itemConfig.name}
      service={recoveryPasswordService}
      triggerValidation={triggerValidation}
      summaryInfoProps={{
        name: itemConfig.name,
        description: itemConfig.description,
        publisher: itemConfig.publisher,
        devices: itemConfig.devices,
        requirements: itemConfig.osRequirements,
      }}
      transformFromApi={transformFromApi}
      transformToApi={transformToApi}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      isIconEditable
      supportsRules
      supportsDuplication
    >
      <ManageCard
        setting={model.manage}
        update={updateManage}
        isDisabled={pageState.isDisabled}
        validationDep={validationDep}
      />
      <LegacyCard
        setting={model.legacy}
        update={updateLegacy}
        isDisabled={pageState.isDisabled}
        validationDep={validationDep}
      />
    </LibraryItemPage>
  );
};

const RecoveryPassword = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState: initial,
    useService: useRecoveryPasswordService,
  });
  const blueprintConflicts = useBlueprintConflicts();

  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return (
    <RecoveryPasswordPage
      {...pageProps}
      blueprintConflicts={blueprintConflicts}
    />
  );
};

export default RecoveryPassword;
