import { Button, Icon } from '@kandji-inc/bumblebee';
import { resendInviteToUser } from 'app/_actions/company';
import { setModal, setSnackbar } from 'app/_actions/ui';
import { deleteUser, getCompanyUsers, updateUser } from 'app/_actions/users';
import {
  UserRoleLabels,
  UserRoleOptions,
  UserRoles,
  colors,
} from 'app/common/constants';
import { i18n } from 'i18n';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
/* istanbul ignore file */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import history from '../../../../router/history';
import {
  BootstrapTable,
  TableHeaderColumn,
} from '../../../common/BootstrapTable';
import { Table } from '../../../common/Table';
import AwesomeDropdown from '../../../interface/AwesomeDropdown';
import BpParamSelect, {
  bpParamSelectVariant,
} from '../../../interface/BpParamSelect';
import { LineLoader } from '../../../interface/LineLoader';
import SearchString from '../../../interface/SearchString';
import { CompanyAccessLevelHelper } from '../../../interface/tooltips/AccessLevelsHelper';

const DEFAULT_SIZE_PER_PAGE = 10;

const StyledButton = styled(Button)`
  &:hover {
    background-color: var(--color-neutral-0);
  }
`;

class CompanyUsersTable extends Table {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      selectedRows: new Set(),
      sizePerPage: DEFAULT_SIZE_PER_PAGE,
      pageSizes: [10, 25, 50, 100, 300],
    };
    this.defaultPageSize = DEFAULT_SIZE_PER_PAGE;
    this.fetchFunc = props.getCompanyUsers;
    this.serverSidePagination = true; // Don't remove! It's used on parent
    this.selectRowId = 'id';
    this.formatName = this.formatName.bind(this);
    this.renderActionButtons2 = this.renderActionButtons2.bind(this);
    this.onDeleteMultiple = this.onDeleteMultiple.bind(this);
    this.resendInvite = this.resendInvite.bind(this);
  }

  goToUser = (row, columnIndex, rowIndex, event) => {
    if (event.target.type !== 'button') {
      history.push(`/my-company/users/${row.id}`);
    }
  };

  resendInvite(email) {
    const { setSnackbar: callSetSnackbar } = this.props;
    this.setState({ isResending: email }, () =>
      resendInviteToUser({ email })
        .then(() => callSetSnackbar(i18n.t('Invite was sent')))
        .catch(() => {
          callSetSnackbar(i18n.common.error());
        })
        .finally(() => {
          this.setState({ isResending: false });
        }),
    );
  }

  formatName(cell, row) {
    const { userId } = this.props;
    if (userId === row.id) {
      return (
        <>
          {cell}
          <span style={{ fontSize: 10, color: colors.grey450 }}>
            {i18n.t('You')}
          </span>
        </>
      );
    }
    return cell;
  }

  formatStatusRow = (cell, row) => {
    const { isResending } = this.state;
    if (row.is_active) {
      return <Icon name="check" className="text-grey" />;
    }
    if (isResending === row.email) {
      return <Icon name="arrows-rotate" className="text-grey pt-0" />;
    }
    return (
      <StyledButton
        icon="envelope"
        onClick={(e) => {
          e.stopPropagation();
          this.resendInvite(row.email);
        }}
        size="small"
        kind="link"
        theme="dark"
      >
        {i18n.t('Resend Invite')}
      </StyledButton>
    );
  };

  formatLevelRow = (cell, row, currentCompany) => {
    const { updateUser: callUpdateUser, setModal: callSetModal } = this.props;
    const { isLoadingUserId } = this.state;

    if (get(currentCompany, 'owner') === row.id) {
      return i18n.t('Account Owner');
    }
    return (
      <BpParamSelect
        value={row.role}
        options={UserRoleOptions}
        onClick={(e) => e.stopPropagation()}
        onChange={(option) => {
          if (option.value !== row.role) {
            callSetModal('SIMPLE_DIALOG', {
              get text() {
                return i18n.t('Change an access');
              },
              caption: (
                <span>
                  {i18n.t('You are about to change an access level for')}{' '}
                  <b>{row.email}</b>
                  <br />
                  {i18n.t('from')} <b>{row.role}</b> {i18n.t('to')}{' '}
                  <b>{UserRoleLabels[option.value]}</b>.
                </span>
              ),
              effectText: 'Change',
              effectAction: () =>
                callUpdateUser({ id: row.id, role: option.value }).then(() =>
                  this.fetchData(true),
                ),
            });
          }
        }}
        variant={bpParamSelectVariant.small}
        disabled={isLoadingUserId === row.id}
      />
    );
  };

  formatActionsRow = (cell, row, currentCompany, isAccountOwner) => {
    const { userId, setModal: callSetModal } = this.props;
    const actions = [];
    if (
      isAccountOwner &&
      get(currentCompany, 'owner') !== row.id &&
      row.is_active &&
      row.role === UserRoles.admin
    ) {
      actions.push({
        label: i18n.t('Transfer Ownership'),
        action: () =>
          callSetModal('TRANSFER_OWNERSHIP', {
            user: row,
            isCompany: true,
            companyId: currentCompany.id,
          }), // TODO TRANSFER_OWNERSHIP
        iconClass: 'arrow-right-arrow-left',
        colorClass: 'c-orange-500',
      });
    }
    if (get(currentCompany, 'owner') !== row.id && userId !== row.id) {
      actions.push({
        label: i18n.t('Delete User'),
        action: () =>
          callSetModal('USER_DELETE', {
            delete: this.onDelete,
            idsToDelete: [row.id],
          }),
        iconClass: 'trash-can',
        colorClass: 'c-orange-500',
      });
    }

    if (isEmpty(actions)) {
      return null;
    }
    return (
      <div className="d-flex justify-content-center">
        <div style={{ width: 50 }}>
          <AwesomeDropdown horizontal="right" options={actions} />
        </div>
      </div>
    );
  };

  onDeleteMultiple = (ids) => {
    const { deleteUser: callDeleteUser } = this.props;
    const promises = ids.map((id) => callDeleteUser(id));
    return Promise.all(promises).then((res) => {
      this.setState({ selectedRows: new Set() });
      this.fetchData(true);
      return Promise.resolve(res);
    });
  };

  onDelete = (userId) => {
    const { deleteUser: callDeleteUser } = this.props;
    return callDeleteUser(userId).then((res) => {
      this.fetchData(true);
      return Promise.resolve(res);
    });
  };

  createCustomToolBar = (props) => (
    <div
      className="d-flex flex-dir-row align-items-center"
      style={{ width: '100%', paddingTop: 20, marginLeft: -15 }}
    >
      <div className="col-sm-6 col-md-6 col-lg-8" style={{ paddingTop: 10 }}>
        <h3
          style={{
            paddingBottom: '2px',
            fontFamily: `'Sharp Sans',sans-serif`,
            fontSize: '23px',
            fontWeight: 600,
          }}
        >
          {i18n.t('Admin Team')}
        </h3>
        {props.components.btnGroup}
      </div>
      <div className="col-sm-6 col-md-6 col-lg-4 d-flex justify-content-end">
        {/* {props.components.searchPanel} */}
        <SearchString
          label={i18n.t('Search Users') + '...'}
          searchFunc={(e) => this.table.handleSearch(e)}
          iconPosition="right"
        />
      </div>
    </div>
  );

  renderActionButtons2() {
    const { selectedRows } = this.state;
    const { setModal: callSetModal } = this.props;
    const actions = [
      {
        get label() {
          return i18n.t('Delete Selected Users');
        },
        action: () =>
          callSetModal('USER_DELETE', {
            delete: this.onDeleteMultiple,
            idsToDelete: [...selectedRows],
          }),
        iconClass: 'fas fa-trash',
        colorClass: 'c-orange-500',
      },
    ];

    return (
      <div style={{ width: 150 }}>
        <AwesomeDropdown
          horizontal="left"
          buttonText={i18n.t('Bulk Actions')}
          options={actions}
          disabled={!selectedRows.size}
        />
      </div>
    );
  }

  render() {
    const { userId, currentCompany } = this.props;
    const { isLoading, currPage, sizePerPage, data } = this.state;
    if (isLoading) {
      /* istanbul ignore next */
      return <LineLoader isDelayed />;
    }
    const options = {
      ...this.defaultOptions,
      defaultSortName: 'fullName',
      defaultSortOrder: 'asc',
      onRowClick: (row, columnIndex, rowIndex, event) =>
        this.goToUser(row, columnIndex, rowIndex, event),
      page: currPage,
      sizePerPage,
      toolBar: this.createCustomToolBar,
    };
    const isAccountOwner = get(currentCompany, 'owner', 'noOwner') === userId;
    return (
      <BootstrapTable
        ref={(node) => {
          this.table = node;
        }}
        data={data}
        version="4"
        pagination
        remote={() => ({
          pagination: true,
        })}
        trClassName="users-table-tr"
        options={options}
        search
        searchPlaceholder={i18n.t('Search users') + '...'}
        containerClass="bst-borderless company-user-table old-table"
      >
        <TableHeaderColumn
          dataField="fullName"
          tdStyle={{ color: colors['grey-999'] }}
          columnClassName="column-border-right"
          className="header-column-border-right"
          dataSort
          dataFormat={this.formatName}
        >
          {i18n.t('Name')}
        </TableHeaderColumn>
        <TableHeaderColumn dataField="email" dataSort>
          {i18n.t('Email')}
        </TableHeaderColumn>

        {/* ---------- Access Level ---------- */}
        <TableHeaderColumn
          dataField="role"
          dataFormat={(cell, row) =>
            this.formatLevelRow(cell, row, currentCompany)
          }
          tdStyle={{ textTransform: 'capitalize', overflow: 'visible' }}
          dataSort
        >
          {i18n.t('Access Level')}{' '}
          <i
            className="fas fa-question-circle light-grey-icon user-header-helper"
            id="accessLevel"
          />
          {CompanyAccessLevelHelper('accessLevel')}
        </TableHeaderColumn>

        {/* ---------- Status ---------- */}
        <TableHeaderColumn
          dataField="is_active"
          width="150px"
          thStyle={{ textAlign: 'center' }}
          tdStyle={{ textAlign: 'center' }}
          dataFormat={this.formatStatusRow}
          dataSort
        >
          {i18n.t('Status')}
        </TableHeaderColumn>

        {/* ---------- Transfer Ownership ---------- */}
        <TableHeaderColumn
          width="150px"
          tdStyle={{ overflow: 'visible' }}
          dataFormat={(cell, row) =>
            this.formatActionsRow(cell, row, currentCompany, isAccountOwner)
          }
        />
        <TableHeaderColumn dataField="id" hidden isKey />
      </BootstrapTable>
    );
  }
}

const mapStateToProps = (state) => ({
  userId: state.account.user.id,
  currentCompany: state.account.company,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteUser,
      setSnackbar,
      setModal,
      updateUser,
      getCompanyUsers,
    },
    dispatch,
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CompanyUsersTable),
);
