import type { IconNames } from '@kandji-inc/nectar-ui';
import type { Column, DeviceFamily } from '@tanstack/react-table';

export const deviceFamilyToIconMap: Record<string, IconNames> = {
  Mac: 'sf-desktop-computer',
  iPhone: 'sf-iphone',
  iPad: 'sf-ipad-landscape',
  AppleTV: 'sf-apple-tv',
};

export function getColumnId(
  column: Partial<{ name: string; category: string }>,
): string {
  const prefix = column.category ? `${column.category}.` : '';
  return `${prefix}${column.name}`;
}

export function areColumnsInOrder(
  columns: Partial<{ name: string; category: string }>[],
  columnOrder: string[],
): boolean {
  if (columns.length !== columnOrder.length) {
    return false;
  }

  return columns.every(
    (column, index) => getColumnId(column) === columnOrder[index],
  );
}

export function hasColumnVisibilityChanged(
  columns: Partial<{ name: string; category: string; visible: boolean }>[],
  columnVisibility: Record<string, boolean>,
): boolean {
  return columns.some(
    (col) => columnVisibility[getColumnId(col)] !== col.visible,
  );
}

export function applyColumnOrderAndVisibility({
  columns,
  columnOrder,
  category,
  columnSizes,
  columnVisibility,
}: {
  columns: Partial<{ id: string }>[];
  columnOrder: string[];
  category: string;
  columnSizes: Record<string, number>;
  columnVisibility: Record<string, boolean>;
}) {
  const columnDefsFormattedForSaving = columns.map((col) => ({
    category,
    name: col.id,
    visible: col.id ? columnVisibility[col.id] : true,
    size: col.id ? columnSizes[col.id] : undefined,
  }));

  if (!columnOrder.length) {
    return columnDefsFormattedForSaving;
  }

  // Create a map of column names to their objects for quick lookup
  const columnMap = new Map(
    columnDefsFormattedForSaving.map((col) => [col.name, col]),
  );

  // Reorder the columns based on columnOrder
  const reorderedColumns = columnOrder
    .map((name) => columnMap.get(name))
    .filter(Boolean);

  // Add any columns that are in columnDefsFormattedForSaving but not in columnOrder
  columnDefsFormattedForSaving.forEach((col) => {
    if (col.name && !columnOrder.includes(col.name)) {
      reorderedColumns.push(col);
    }
  });

  const result: Array<{
    name: string | undefined;
    visible: boolean | undefined;
    category: string;
    size: number | undefined;
  }> = [];

  for (const col of reorderedColumns) {
    if (col) {
      result.push(col);
    }
  }

  return result;
}

export const filterColumns = (
  columns: {
    columnDefs?: Column<{}, unknown>[];
    alwaysHiddenColumns?: string[];
  },
  search: string,
  deviceFamilyFilter: string[],
  dataTypeFilter: string[],
): Column<{}, unknown>[] => {
  return (
    columns.columnDefs?.filter((column) => {
      const notAlwaysHidden = !columns.alwaysHiddenColumns?.includes(column.id);
      // Text search on header
      const matchesSearch = column.meta.displayName
        ?.toString()
        .toLowerCase()
        .includes(search.toLowerCase());

      // Device family filter
      const matchesDeviceFamily =
        deviceFamilyFilter.length === 0 ||
        column.meta?.deviceFamily?.includes('all') ||
        deviceFamilyFilter.some((family) =>
          column.meta?.deviceFamily?.includes(family as DeviceFamily),
        );

      // Data type filter
      const matchesDataType =
        (column.meta?.filterType && dataTypeFilter.length === 0) ||
        (column.meta?.filterType &&
          dataTypeFilter.includes(column.meta?.filterType));

      return (
        notAlwaysHidden &&
        matchesSearch &&
        matchesDeviceFamily &&
        matchesDataType
      );
    }) || []
  );
};
