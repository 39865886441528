/* istanbul ignore file */

import React from 'react';

import { formatTime } from '../../common/helpers';

const pendingMessage = 'Connector details are pending...';

const entry = ({ details }, { connectorList }) => {
  const { connector } = details;

  const { name, bound_domain } =
    connectorList?.find(({ id }) => id === connector) || {};

  return {
    icon: 'wifi-slash',
    iconText: 'Connector offline',
    text: 'Connector offline',
    description: name || bound_domain || pendingMessage,
  };
};

const detail = ({ action_type, details }, { connectorList }) => {
  const { connector, time } = details;

  const { name, bound_domain } =
    connectorList?.find(({ id }) => id === connector) || {};

  return (
    <div className={`activity-entry-details ${action_type}`}>
      <p>Offline on: {formatTime(time, false, null, null, true)}</p>
      <p>Connector name: {name || bound_domain || pendingMessage}</p>
    </div>
  );
};

export default { entry, detail };
