/* istanbul ignore file */
import {
  $t,
  formatDateTime,
  formatNumber,
  getI18nJSLocale,
  getI18nLanguage,
  getI18nLocale,
  render,
  t,
  ut,
} from '@kandji-inc/nectar-i18n';
import { common } from './common';
import { format } from './format';

const LOCALES = {
  en_US: 'en_US',
  en_GB: 'en_GB',
  es_419: 'es_419',
  de: 'de',
  ja_JP: 'ja_JP',
} as const;

const DEFAULT_I18N_PROVIDER = 'noop';
const DEFAULT_I18N_LOCALE = LOCALES.en_US;

const createMap =
  <TMap extends Record<string, () => string>>(map: TMap) =>
  (variable: keyof TMap) => {
    if (variable in map) {
      const translationFn = map[variable];
      if (translationFn) {
        return translationFn();
      }
    }
    throw new Error(`Missing translation for variable: ${String(variable)}`);
  };

export const i18n = {
  // wrapped to consolidate imports
  getLocale: getI18nLocale,
  getJSLocale: getI18nJSLocale,
  getLanguage: getI18nLanguage,
  render,
  formatDateTime,
  formatNumber,
  t,
  ut,
  $t,
  // extra helpers
  createMap,
  common,
  format,
  DEFAULT_I18N_PROVIDER,
  DEFAULT_I18N_LOCALE,
  LOCALES,
};
