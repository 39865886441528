import { Button } from '@kandji-inc/bumblebee';
import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';

const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <FormGroup className={touched && error ? 'params-error' : ''}>
    <Input bsSize="sm" {...input} placeholder={label} type={type} />
    {touched && error && <span className="params-error-message">{error}</span>}
  </FormGroup>
);

export let ResetPasswordForm = ({ handleSubmit }) => (
  <form
    onSubmit={handleSubmit}
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingBottom: 30,
    }}
  >
    <Field
      name="new_password"
      type="password"
      label="New Password"
      component={renderField}
    />
    <Field
      name="password_confirmation"
      type="password"
      label="Confirm New Password"
      component={renderField}
    />
    <Button type="submit">Change</Button>
  </form>
);

const validate = (values) => {
  const errors = {};
  if (!values.new_password) {
    errors.new_password = 'Required';
  } else {
    if (values.new_password.length < 8) {
      errors.new_password =
        'Password should contain at least 8 characters or digits';
    }
    if (values.new_password !== values.password_confirmation) {
      errors.password_confirmation = 'Passwords should match';
    }
  }
  return errors;
};

ResetPasswordForm = reduxForm({
  form: 'resetPasswordForm',
  validate,
})(ResetPasswordForm);
