/* istanbul ignore file */
import { useQuery } from '@tanstack/react-query';
import { vulnerabilityApi } from 'src/app/api/base';

const useGetVulnerabilities = (keys: Array<any> = [], params: any = {}) => {
  const { data: apiRes, isLoading } = useQuery({
    queryKey: ['vulnerabilities', ...(keys || [])],
    queryFn: () => vulnerabilityApi('vulnerabilities').get(params),
  });

  const data = apiRes?.data;
  const vulnerabilities = data?.results || [];
  const count = data?.total;

  return { vulnerabilities, count, isLoading };
};

export { useGetVulnerabilities };
