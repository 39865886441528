import './styles.scss';

import React, { useState, useEffect } from 'react';

import { i18n } from 'i18n';

import { Breadcrumb, Tabs } from '@kandji-inc/bumblebee';

import { links } from 'app/common/constants';
import { LineLoader } from 'app/components/interface/LineLoader';

import { Library } from 'features/library-items/template';

import { Whoops404 } from 'pages/404';

import { INT_TYPES } from '../../constants';

import WrappedPage from '../generic/building-blocks/page-wrapper';
import ModalWelcome from '../generic/welcome-modal';

import ModalCreateLibraryItem from './modals/create-library-item';
import ModalDeleteConnector from './modals/delete-connector';
import ModalDeleteIntegration from './modals/delete-integration';

import PanelAddServer from './panels/add-server';
import PanelAssignServer from './panels/assign-server';
import PanelDeleteConnector from './panels/delete-connector';
import PanelDeleteIntegration from './panels/delete-integration';
import PanelDeleteServer from './panels/delete-server';
import PanelEditServer from './panels/edit-server';
import PanelHandleConflictLibraryItem from './panels/handle-conflict-library-item';

import Activity from './tabs/activity';
import Overview from './tabs/overview';
import Servers from './tabs/servers';

import Store from './store';
import Summary from './summary';
import CONFIG_SHAPE from './util';

const Single = ({ config }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error] = useState(null);

  const { title } = config;

  const crumbs = [
    {
      title: i18n.t('Integrations'),
      url: links.integrations.root,
    },
    {
      title,
    },
  ];

  const tabs = [
    {
      label: i18n.t('Overview'),
    },
    {
      label: i18n.t('Servers'),
      route: 'servers',
    },
    {
      label: i18n.t('Activity'),
      route: 'activity',
    },
  ];

  useEffect(() => {
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <LineLoader isDelayed />;
  }
  // ignore this until we have BE functionality to test
  /* istanbul ignore next */
  if (error) {
    return <Whoops404 />;
  }
  return (
    <Store>
      <Library className="k-adcs-single">
        <Breadcrumb crumbs={crumbs} classes={{ wrapper: 'b-mt3 b-mb2' }} />

        <Summary config={config} />

        <Tabs className="k-tab-menu" tabs={tabs}>
          <Overview tabid="Overview" />
          <Servers tabid="Servers" />
          <Activity tabid="Activity" />
        </Tabs>

        <ModalWelcome
          onClose={() => {}}
          type={INT_TYPES.adcs}
          externalTrigger
          noRedirectOnClose
        />

        <ModalDeleteIntegration />
        <ModalDeleteConnector />
        <ModalCreateLibraryItem />

        <PanelAddServer />
        <PanelAssignServer />
        <PanelEditServer />
        <PanelDeleteIntegration />
        <PanelDeleteConnector />
        <PanelDeleteServer />
        <PanelHandleConflictLibraryItem />
      </Library>
    </Store>
  );
};

Single.propTypes = {
  config: CONFIG_SHAPE.isRequired,
};

export default WrappedPage(Single);
