import { Banner, Chip, Flex, Radio } from '@kandji-inc/bumblebee';
import React, { memo } from 'react';
import './managed-card.css';

import { Setting } from 'features/library-items/template';

import AutoPassword from './auto-password';
import SpecifyPassword from './specify-password';

const ManageCard = (props) => {
  const { setting, update, isDisabled } = props;

  return (
    <Setting.Card>
      <Setting.Header className="k-ss2-header">
        <h3 className="b-h3">Manage recovery password</h3>
      </Setting.Header>
      <Setting.SubHeader>
        <p className="b-txt">
          Set a recovery/firmware password to protect against unauthorized
          booting to another disk, using single-user mode, “option booting” and
          other alternative startup commands.
        </p>
      </Setting.SubHeader>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Helpers>
            <p className="b-txt-light">
              Specify whether the recovery password should be automatically
              generated on the device and stored in Kandji, or choose to set and
              manage a custom recovery password.
            </p>
            <div className="b-flex-wrap-gtiny">
              <Chip
                kind="tertiary-light"
                text="NIST"
                className="k-ade-chip--m0"
              />
              <Chip
                kind="tertiary-light"
                text="STIG"
                className="k-ade-chip--m0"
              />
              <Chip
                kind="tertiary-light"
                text="CIS"
                className="k-ade-chip--m0"
              />
              <Chip
                kind="tertiary-light"
                text="FIPS"
                className="k-ade-chip--m0"
              />
              <Chip
                kind="tertiary-light"
                text="macOS 10.11+"
                className="k-ade-chip--m0"
              />
            </div>
          </Setting.Helpers>
          <Setting.Controls>
            <Flex direction="col" gapType="gap2">
              <Radio
                label="Automatically generate password"
                checked={setting.isAutoPassword}
                disabled={isDisabled}
                onChange={() => update('isAutoPassword', true)}
              />
              <Radio
                label="Specify the recovery password "
                checked={!setting.isAutoPassword}
                disabled={isDisabled}
                onChange={() => update('isAutoPassword', false)}
              />
            </Flex>
          </Setting.Controls>
          <Setting.SecondaryControls className="k-ade-secondary-ctrl-grid-gap-align">
            <Setting.SecondaryControlsRow>
              <Setting.Helpers>
                <p className="b-txt-light">
                  {setting.isAutoPassword
                    ? `Automatically rotate the recovery password on a regular schedule.`
                    : `Specify a custom recovery password to be set.`}
                </p>
                {!setting.isAutoPassword && (
                  <Banner
                    theme="warning"
                    icon="octagon-exclamation"
                    kind="block"
                  >
                    <p>
                      Once set, the recovery password cannot be modified without
                      providing the existing password. A lost password is not
                      recoverable, and this feature should always be tested on a
                      single Mac computer before deployment to your fleet.
                    </p>
                  </Banner>
                )}
              </Setting.Helpers>
              <Setting.Controls>
                {setting.isAutoPassword && <AutoPassword {...props} />}
                {!setting.isAutoPassword && <SpecifyPassword {...props} />}
              </Setting.Controls>
            </Setting.SecondaryControlsRow>
          </Setting.SecondaryControls>
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export default memo(ManageCard);
