import { Button, Icon } from '@kandji-inc/bumblebee';
import { colors } from 'app/common/constants';
import { truncate } from 'app/common/style-utils';
import camelCase from 'lodash/camelCase';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import PropTypes from 'prop-types';
/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import DayPicker, { DateUtils } from 'react-day-picker';
import styled from 'styled-components';
import { useDateFormat } from '../common/helpers';
import { useOnClickOutside } from '../common/hooks';

const classNames = require('classnames');

const WrapperDefault = styled.div.attrs(({ leftIcon }) => ({
  justifyContent: leftIcon ? 'space-between' : 'center',
}))`
  display: flex;
  align-items: center;
  position: relative;
  font-size: 14px;
  font-weight: 400;
  font-family: var(--font-family-primary);
  transition: box-shadow 0.3s;
  cursor: pointer;
  height: 55px;
  justify-content: ${(props) => props.justifyContent};
`;

const WrapperSmall = styled(WrapperDefault)`
  height: 30px;
`;

const SubWrapper = styled.div.attrs(({ opened }) => ({
  zIndex: opened ? 10 : 9,
  boxShadow: opened ? '0px 10px 30px -5px rgba(0, 0, 0, 0.2)' : null,
}))`
  position: absolute;
  top: 0;
  width: 100%;
  z-index: ${(props) => props.zIndex};
  box-shadow: ${(props) => props.boxShadow};
`;

const HeaderDefault = styled.div.attrs(({ opened }) => ({
  boxShadow: opened
    ? `0 0 0 1px ${colors['grey-250']}`
    : `0 0 0 1px ${colors['grey-250']}`,
  backgroundColor: opened ? colors.white : 'var(--color-neutral-20)',
  borderBottomRightRadius: opened ? 0 : 5,
  borderBottomLeftRadius: opened ? 0 : 5,
}))`
  display: flex;
  align-items: center;
  width: 100%;
  height: 55px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding: 10px 15px;
  transition: box-shadow 0.15s;
  box-shadow: ${(props) => props.boxShadow};
  background-color: ${(props) => props.backgroundColor};
  border-bottom-right-radius: ${(props) => props.borderBottomRightRadius}px;
  border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius}px;
`;

const HeaderSmall = styled(HeaderDefault)`
  height: 30px;
  padding-top: 0;
  padding-bottom: 0;
`;

const Body = styled.div.attrs(({ opened }) => ({
  boxShadow: opened ? `0 0 0 1px ${colors['grey-250']}` : null,
}))`
  width: 100%;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: ${colors.white};
  transition: box-shadow 0.15s;
  box-shadow: ${(props) => props.boxShadow};
`;

const Option = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px 0;
  ${(props) => (props.selected ? `color: ${colors.yellow500}` : null)};
  &:last-child {
    padding-bottom: 20px;
  }
  &:hover {
    color: ${colors.yellow500};
  }
  transition: color 0.1s;
`;

const DayPickerWrapper = styled.section`
  display: flex;
  flex-flow: column;
  min-height: 1.5rem;
  font-weight: 500;
  margin: 10px 20px;
`;

const Delimiter = styled.section`
  width: 100%;
  height: 0;
  border-bottom: 1px solid ${colors['grey-200']};
  margin-top: 10px;
`;

const OptionLabel = styled.div``;

const IconWrapper = styled.div`
  color: ${colors.grey900};
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const Arrow = styled(Icon).attrs(({ opened }) => ({
  color: opened ? colors.black : colors.grey500,
}))`
  margin-left: auto;
  font-size: 16px;
  padding-left: 10px;
  transition: color 0.2s;
  color: ${(props) => props.color};
`;

const Label = styled.label`
  height: 16px;
  font-family: var(--font-family-primary);
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.2em;
  margin-bottom: 8px;
  color: var(--color-neutral-60);
`;

const LabelAndOptionWrapper = styled.div`
  ${truncate('230px')};
  display: flex;
  flex-direction: column;
`;

const OptionLabelWrapper = styled.div`
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  ${(props) => (props.selected ? `color: ${colors.yellow500}` : null)};
`;

const wrapperMapping = {
  default: WrapperDefault,
  small: WrapperSmall,
};

const headerMapping = {
  default: HeaderDefault,
  small: HeaderSmall,
};

const FilterSelect = ({
  value,
  options,
  label,
  multi,
  onChange,
  disabled,
  variant,
  onClick,
  className,
  hasCustomRange,
}) => {
  const dateFormat = useDateFormat();

  const getDate = (v) => {
    if (v.toString().indexOf('@') > -1) {
      const dateFromSelected = v.toString().split('@');
      return {
        from: new Date(dateFromSelected[0]),
        to: new Date(dateFromSelected[1]),
      };
    }
    return { from: null, to: null };
  };

  const [opened, setOpened] = useState(false);
  const [selected, setSelected] = useState(value || []);
  const [selectedDate, setSelectedDate] = useState(getDate(value));
  const [showCalendar, setShowCalendar] = useState(false);

  const ref = React.useRef(null);
  const optionsLength = options.filter((option) => !isEmpty(option)).length;
  useOnClickOutside(ref, () => {
    if (!disabled && opened) {
      setOpened(!opened);
    }
  });

  useEffect(() => {
    if (multi && !opened && onChange) {
      onChange(selected);
    }
    if (hasCustomRange) {
      setShowCalendar(false);
    }
  }, [opened]);

  useEffect(() => {
    setSelected(value);
    setSelectedDate(getDate(value));
  }, [value]);

  const handleDayClick = (day) => {
    const range = DateUtils.addDayToRange(day, selectedDate);
    setSelectedDate(range);
  };

  const getTitle = () => {
    if (hasCustomRange && selected.toString().indexOf('@') > -1) {
      return `${moment(selectedDate.from).format(dateFormat)} - ${moment(
        selectedDate.to,
      ).format(dateFormat)}`;
    }
    if (multi) {
      return options
        .filter((el) => selected.includes(el.value))
        .map((el) => el.label)
        .join(', ');
    }
    if (!multi) {
      return options.find((el) => el.value === selected).label;
    }
  };

  const change = (option) => {
    if (multi) {
      if (option === 'ALL') {
        setSelected([option]);
        if (onChange) {
          onChange([option]);
        }
      } else {
        const newSelected = selected.includes(option)
          ? selected.filter((s) => s !== option).filter((s) => s !== 'ALL')
          : [...selected, option].filter((s) => s !== 'ALL');
        const preparedValues =
          newSelected.length < 1 || newSelected.length >= optionsLength - 1
            ? ['ALL']
            : newSelected;
        setSelected(preparedValues);
      }
    } else {
      setOpened(!opened);
      if (onChange) {
        onChange(option);
      }
    }
  };

  const renderCustomDate = () => {
    const isSelected =
      selectedDate.from && selectedDate.to
        ? selected
            .toString()
            .indexOf(
              `${selectedDate.from.toISOString()}@${selectedDate.to.toISOString()}`,
            ) > -1
        : false;

    const modifiersStyles = {
      disabled: {
        color: colors['grey-200'],
      },
      today: {
        color: colors['grey-20'],
        fontWeight: 500,
      },
      selected: {
        backgroundColor: colors['grey-400'],
        color: colors['grey-999'],
      },
    };

    if (showCalendar) {
      return (
        <DayPickerWrapper key="DayPickerSelector">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              textAlign: 'center',
              marginTop: '10px',
            }}
          >
            <Button
              kind="link"
              disabled={!(selectedDate.from && selectedDate.to)}
              onClick={() => {
                if (selectedDate.from && selectedDate.to) {
                  setShowCalendar(false);
                  const preparedDate = { ...selectedDate };
                  preparedDate.from.setHours(0, 0, 0, 0);
                  preparedDate.to.setHours(23, 59, 59, 999);
                  change(
                    `${preparedDate.from.toISOString()}@${preparedDate.to.toISOString()}`,
                  );
                }
              }}
            >
              Select
            </Button>
            <Button
              kind="link"
              disabled={!(selectedDate.from || selectedDate.to)}
              onClick={() => {
                setSelectedDate({ from: null, to: null });
                setSelected(multi ? [options[0].value] : options[0].value);
                if (onChange) {
                  onChange(options[0].value);
                }
              }}
            >
              Reset
            </Button>
          </div>
          <DayPicker
            className="filter-date-custom-range"
            selectedDays={[selectedDate.from, { ...selectedDate }]}
            toMonth={new Date()}
            modifiers={{
              start: selectedDate.from,
              end: selectedDate.to,
              disabled: { after: new Date() },
            }}
            modifiersStyles={modifiersStyles}
            onDayClick={handleDayClick}
          />
        </DayPickerWrapper>
      );
    }
    return (
      <Option
        className={classNames({ active: isSelected })}
        onClick={() => setShowCalendar(!showCalendar)}
      >
        <OptionLabelWrapper selected={isSelected}>
          {selectedDate.from && selectedDate.to
            ? `${moment(selectedDate.from).format(dateFormat)} - ${moment(
                selectedDate.to,
              ).format(dateFormat)}`
            : 'Custom Range'}
        </OptionLabelWrapper>
        {isSelected && (
          <IconWrapper>
            <Icon name="check" size="sm" />
          </IconWrapper>
        )}
      </Option>
    );
  };

  const Header = headerMapping[variant];
  const Wrapper = wrapperMapping[variant];
  return (
    <Wrapper className={className} opened={opened} ref={ref} onClick={onClick}>
      {/* SubWrapper needed for shadow */}
      <SubWrapper opened={opened}>
        <Header opened={opened} onClick={() => !disabled && setOpened(!opened)}>
          <LabelAndOptionWrapper>
            {label && <Label opened={opened}>{label}</Label>}
            {getTitle()}
          </LabelAndOptionWrapper>
          <Arrow opened={opened} name={opened ? 'angle-up' : 'angle-down'} />
        </Header>

        {opened && options.length && (
          <Body opened={opened}>
            <Scrollbars autoHide autoHeight autoHeightMax={350}>
              {options.map((item) => {
                const isSelected =
                  typeof selected === 'string'
                    ? item.value === selected
                    : selected.includes(item.value);
                return (
                  <>
                    {!isEmpty(item) && (
                      <Option
                        key={camelCase(item.value)}
                        onClick={() => change(item.value)}
                        className={classNames({
                          active: item.value === selected,
                        })}
                        selected={isSelected}
                      >
                        <OptionLabel>{item.label}</OptionLabel>
                        {isSelected && (
                          <IconWrapper>
                            <Icon name="check" size="sm" />
                          </IconWrapper>
                        )}
                      </Option>
                    )}
                    {isEmpty(item) && <Delimiter />}
                  </>
                );
              })}
              {hasCustomRange && renderCustomDate()}
            </Scrollbars>
          </Body>
        )}
      </SubWrapper>
    </Wrapper>
  );
};

FilterSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'small']),
  multi: PropTypes.bool,
  hasCustomRange: PropTypes.bool,
};

FilterSelect.defaultProps = {
  value: '',
  onChange: null,
  onClick: null,
  disabled: false,
  label: '',
  className: null,
  variant: 'default',
  multi: false,
  hasCustomRange: false,
};

export default FilterSelect;
