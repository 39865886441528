import { Checkbox, TextInput } from '@kandji-inc/bumblebee';
import React from 'react';

const DrawerContent = (props) => {
  const { update, setting, defaults } = props;
  const { shortDescription, longDescription, isRequireRead, displayName } =
    setting;

  return (
    <>
      <p className="b-txt">
        Customize the appearance of the app. You can replace the icon, change
        the name and button label, and add a short description or instruction.
      </p>
      <div className="k-ss2-drawer-section --content">
        <div className="k-ss2-drawer-setting">
          <div className="k-ss2-drawer-setting-with-toggle">
            <div>
              <p className="b-txt">Name</p>
              <p className="b-txt-light">Change the name of the app.</p>
            </div>
          </div>
          <div className="k-ss2-drawer-setting-extra">
            <TextInput
              placeholder={displayName || defaults.name}
              maxLength={30}
              fieldsGrid="k-ss2-input-grid"
              value={displayName}
              onChange={(e) => update('displayName', e.target.value)}
            />
          </div>
        </div>
        <div className="k-ss2-drawer-setting">
          <div className="k-ss2-drawer-setting-with-toggle">
            <div>
              <p className="b-txt">
                Short description{' '}
                <span className="b-txt-light2">(optional)</span>
              </p>
              <p className="b-txt-light">
                Include a short description of the app. Does not allow line
                breaks.
              </p>
            </div>
          </div>
          <div className="k-ss2-drawer-setting-extra1">
            <div className="b-mt2">
              <TextInput
                textArea
                maxLength={170}
                fieldsGrid="k-ss2-input-grid"
                value={shortDescription || ''}
                onChange={(e) => update('shortDescription', e.target.value)}
                placeholder="Add a short description"
              />
            </div>
          </div>
        </div>

        <div className="k-ss2-drawer-setting">
          <div className="k-ss2-drawer-setting-with-toggle">
            <div>
              <p className="b-txt">
                Long description{' '}
                <span className="b-txt-light2">(optional)</span>
              </p>
              <p className="b-txt-light">
                Include a long description of the app.
              </p>
            </div>
          </div>
          <div className="k-ss2-drawer-setting-extra1">
            <div className="b-mt2">
              <TextInput
                style={{ minHeight: '212px' }}
                textArea
                maxLength={4000}
                fieldsGrid="k-ss2-input-grid"
                value={longDescription || ''}
                onChange={(e) => update('longDescription', e.target.value)}
                placeholder="Add a long description"
              />
            </div>
          </div>
          <div className="k-ss2-drawer-setting-extra">
            <p className="b-txt b-mb1">Additional option</p>
            <Checkbox
              checked={!!(longDescription && isRequireRead)}
              disabled={!longDescription}
              onChange={() => update('isRequireRead', !isRequireRead)}
              label="Require users to read the description before installing"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DrawerContent;
