import cn from 'classnames';
import { Setting } from 'features/library-items/template';
import React, { useState } from 'react';
import Icon from '../../../../../theme/components/atoms/Icon';
import Avatar from '../../../../../theme/components/molecules/Avatar';
import Drawer from '../../../common/self-service-card/drawer';

const Customization = (props) => {
  const { setting, update, defaults, isDisabled, DrawerContent } = props;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <Setting.Row>
      <Setting.Title>
        <p className="b-txt">Customization</p>
      </Setting.Title>
      <Setting.Helpers>
        <p className="b-txt-light">
          Customize the appearance of the app. You can replace the icon, change
          the name and button label, and add a short description or instruction.
        </p>
      </Setting.Helpers>
      <div className="theme-form-copy">
        <button
          type="button"
          className={cn('btn btn-preview b-up-container')}
          disabled={isDisabled}
          onClick={() => setIsDrawerOpen((p) => !p)}
        >
          <div className="btn-preview-avatar">
            <Avatar title={setting.displayName} image={defaults.icon} />
          </div>
          <div className="btn-preview-copy">
            <h5 className="btn-preview-title">
              <span>{setting.displayName || defaults.name}</span>
              {setting.isRecommended && <Icon icon="star" />}
            </h5>
            <p className="btn-preview-description">
              {setting.shortDescription || defaults.shortDescription}
            </p>
            <span className="btn-preview-button">INSTALL</span>
          </div>
          <div className="btn-preview-action">
            <Icon icon="pencil" />
          </div>
        </button>
      </div>
      <div>
        <Drawer
          isVisible={isDrawerOpen}
          setIsVisible={setIsDrawerOpen}
          setting={setting}
          update={update}
          defaults={defaults}
          DrawerContent={DrawerContent}
        />
      </div>
    </Setting.Row>
  );
};

export default Customization;
