import { Button } from '@kandji-inc/bumblebee';
import React, { Component } from 'react';
import { FormGroup, Input } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';

const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <FormGroup className={touched && error ? 'params-error' : ''}>
    <Input bsSize="sm" {...input} placeholder={label} type={type} />
    {touched && error && <span className="params-error-message">{error}</span>}
  </FormGroup>
);

export class ForgotPasswordForm extends Component {
  render() {
    const { handleSubmit } = this.props;
    return (
      <form
        onSubmit={handleSubmit}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingBottom: 30,
        }}
      >
        <Field
          name="email"
          type="email"
          label="Email"
          component={renderField}
        />
        <Button type="submit">Reset</Button>
      </form>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Required';
  }
  return errors;
};

ForgotPasswordForm = reduxForm({
  form: 'forgotPasswordForm',
  validate,
})(ForgotPasswordForm);
