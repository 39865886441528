import { AUTO_APP_DEFAULT_TIMEZONE, timezones } from 'src/app/common/constants';
import featureFlags from 'src/config/feature-flags';
import { i18n } from 'src/i18n';
import { apiTypes } from '../../library/common';
import {
  FieldsToValidate,
  ManagedOSNameShort,
  RSREnforcementKeys,
  isManagedOSAppName,
} from './updates-card.types';
import type { SelectOption } from './updates-card.types';

export const getTimezones = (liType: string) => {
  const LDFF_autoAppLocalTimezone = featureFlags.getFlag(
    'dc-auto-app-local-timezone',
  );

  return [
    /* istanbul ignore next */ ...(LDFF_autoAppLocalTimezone &&
    liType === apiTypes.AUTO_APP
      ? [AUTO_APP_DEFAULT_TIMEZONE()]
      : []),
    ...timezones.filter((t) => Boolean(t.value)),
  ];
};

export const DEFAULT_TIME_IDX = 24;

export const ENFORCEMENT_KEYS = Object.freeze({
  any: 'any',
  newest: 'newest',
  custom: 'custom',
});

export const VERSION_ENFORCEMENT_OPTIONS = (): SelectOption[] => [
  { value: ENFORCEMENT_KEYS.any, label: i18n.t('Do not manage updates') },
  {
    value: ENFORCEMENT_KEYS.newest,
    label: i18n.t('Automatically enforce new updates'),
  },
  {
    value: ENFORCEMENT_KEYS.custom,
    label: i18n.t('Manually enforce a minimum version'),
  },
];

export const RSR_MINIMUM_MAC_OS_VERSION = 14;
export const RSR_MINIMUM_IOS_IPAD_VERSION = 17;

export function hasMinimumRSRVersion(
  appName: ManagedOSNameShort | string,
  version: string | undefined | null,
) {
  if (!isManagedOSAppName(appName) || version == null) {
    return false;
  }

  switch (appName) {
    case ManagedOSNameShort.IPAD_OS:
    case ManagedOSNameShort.IOS:
      return parseFloat(version) >= RSR_MINIMUM_IOS_IPAD_VERSION;
    case ManagedOSNameShort.MAC_OS:
      return parseFloat(version) >= RSR_MINIMUM_MAC_OS_VERSION;
    default:
      return false;
  }
}

export const RSR_ENFORCEMENT_OPTIONS: SelectOption[] = [
  {
    value: RSREnforcementKeys.DO_NOT_ENFORCE,
    label: 'None',
  },
  {
    value: RSREnforcementKeys.AUTOMATICALY,
    label: 'Automatically enforce new RSR updates',
  },
];

export const DEFAULT_DELAY_OPTION = (): SelectOption => ({
  value: '14',
  label: i18n.common.durationWeeks(2),
});

export const DELAY_OPTIONS = (): SelectOption[] => [
  { value: '1', label: i18n.common.durationDays(1) },
  { value: '2', label: i18n.common.durationDays(2) },
  { value: '7', label: i18n.common.durationWeeks(1) },
  DEFAULT_DELAY_OPTION(),
  { value: '21', label: i18n.common.durationWeeks(3) },
  { value: '30', label: i18n.common.durationMonths(1) },
  { value: '60', label: i18n.common.durationMonths(2) },
  { value: '90', label: i18n.common.durationMonths(3) },
];

export const RSR_ENFORCEMENT_DELAY_OPTIONS = (): SelectOption[] => [
  { value: '1', label: '1 day' },
  { value: '2', label: '2 days' },
  { value: '3', label: '3 days' },
  { value: '5', label: '5 days' },
  { value: '7', label: '1 week' },
  DEFAULT_DELAY_OPTION(),
];

export const OLD_DELAY_VALUES = Object.freeze({
  28: '30',
  56: '60',
  84: '90',
});

export const MOBILE_MANAGED_0S = Object.freeze([
  ManagedOSNameShort.IOS,
  ManagedOSNameShort.IPAD_OS,
  ManagedOSNameShort.TV_OS,
]);

export const DDM_MINIMUM_MACOS_VERSION = 14;
export const DDM_MINIMUM_IOS_IPADOS_VERSION = 17;

export function isManagedOsDdm(
  appName: ManagedOSNameShort | string,
  version: string | undefined | null,
) {
  if (!isManagedOSAppName(appName) || version == null) {
    return false;
  }

  switch (appName) {
    case ManagedOSNameShort.IPAD_OS:
    case ManagedOSNameShort.IOS:
      return parseFloat(version) >= DDM_MINIMUM_IOS_IPADOS_VERSION;
    case ManagedOSNameShort.MAC_OS:
      return parseFloat(version) >= DDM_MINIMUM_MACOS_VERSION;
    default:
      return false;
  }
}

export const FIELDS_TO_VALIDATE = Object.freeze([
  FieldsToValidate.ENFORCEMENT_DEADLINE,
  FieldsToValidate.ENFORCEMENT_TIMEZONE,
  FieldsToValidate.VERSION_ENFORCEMENT,
]);

/**
 * build time options for 00:00 - 23:30
 */
export function buildTimeOptions(): Array<{ value: string; label: string }> {
  const timeOptions = new Array(48);

  const getFormattedTimeValue = (timeValue: number): string =>
    (timeValue / 2).toFixed(2).replace('50', '30').replace('.', ':');

  for (let i = 0; i < timeOptions.length; i++) {
    const timeValue = i;
    const isPM = timeValue > 23;
    const timeSuffix = isPM ? 'PM' : 'AM';
    const twentyFourHourTimeValue = timeValue % 24;
    const isTwelve = twentyFourHourTimeValue < 2;

    let value = getFormattedTimeValue(timeValue);
    let label = `${getFormattedTimeValue(
      twentyFourHourTimeValue,
    )} ${timeSuffix}`;

    // Override of 12:00 / 12:30
    if (isTwelve) {
      const isTwelveExactly = twentyFourHourTimeValue === 0;
      const minuteValue = isTwelveExactly ? '00' : '30';
      const note = isPM ? '(Noon)' : '(Midnight)';

      label = `12:${minuteValue} ${timeSuffix} ${isTwelveExactly ? note : ''}`;
      value = `${isPM ? '12' : '00'}:${minuteValue}`;
    }

    timeOptions[i] = { value, label };
  }

  return timeOptions;
}

export const TIME_OPTIONS = buildTimeOptions();

export const sortVersionsDesc = (a, b) =>
  b.value.localeCompare(a.value, undefined, { numeric: true });

export const sortAutoAppVersionsDesc = (a, b) => {
  const versionComparison = b.value.version.localeCompare(
    a.value.version,
    undefined,
    { numeric: true },
  );
  if (versionComparison === 0) {
    // If versions are equal, compare by build
    return b.value.build.localeCompare(a.value.build, undefined, {
      numeric: true,
    });
  }
  return versionComparison;
};

export const handleArticle = (appName: ManagedOSNameShort | string) =>
  MOBILE_MANAGED_0S.includes(appName as ManagedOSNameShort)
    ? 'https://support.kandji.io/support/solutions/articles/72000572246-configuring-managed-os-for-ios-ipados-and-tvos'
    : 'https://support.kandji.io/support/solutions/articles/72000558742-understanding-managed-os-auto-app-settings';

export const handlePreviousManagedOSName = (
  appName: ManagedOSNameShort | string,
  version: string | undefined | null,
) =>
  isManagedOsDdm(appName, version) ? `${appName} ${parseInt(version) - 1}` : '';
