/* istanbul ignore file */

import React from 'react';

import { formatTime } from '../../common/helpers';

const entry = ({ details }) => {
  const { 'certificate subject': certificate_subject } = details;

  return {
    icon: 'fas fa-file-certificate',
    iconText: 'AD CS certificate issued',
    text: 'AD CS certificate issued',
    description: certificate_subject,
  };
};

const detail = ({ action_type, details }) => {
  const {
    time,
    library_item,
    library_item_name,
    'certificate subject': certificate_subject,
  } = details;

  return (
    <div className={`activity-entry-details ${action_type}`}>
      <p>Issued on: {formatTime(time, false, null, null, true)}</p>
      <p>Library Item: {library_item}</p>
      <p>Library Item name: {library_item_name}</p>
      <p>Subject: {certificate_subject}</p>
    </div>
  );
};

export default { entry, detail };
