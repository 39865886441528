import {
  Checkbox,
  Select,
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import React, { useEffect } from 'react';

import { Setting } from 'features/library-items/template';

const CategoryRow = (props) => {
  const { setting, update, isDisabled, categoryOptions, isSubmitted } = props;
  const fieldsToValidate = ['category'];
  const { refs, onInvalidate } = useInputsValidators(fieldsToValidate, update);

  useEffect(() => {
    onInvalidate(0)(!setting.category);
  }, [setting.category]);

  useRemoveValidationOnUnmount(fieldsToValidate, update);

  return (
    <Setting.Row>
      <Setting.Title>
        <p className="b-txt">Category</p>
      </Setting.Title>
      <Setting.Helpers>
        <p className="b-txt-light">
          Specify the Self Service category for this item. Recommended items
          will have a star next to their name, and will also be displayed in a
          &quot;Recommended&quot; section at the top of the Self Service
          sidebar.
        </p>
      </Setting.Helpers>
      <Setting.Controls>
        <Select
          isDisabled={isDisabled}
          options={categoryOptions}
          placeholder="Category"
          compact
          onChange={(v) => update('category', v.value)}
          value={categoryOptions.find(
            (option) => option.value === setting.category,
          )}
          errorText={(isSubmitted && !setting.category && 'Required') || ''}
        />
      </Setting.Controls>
      <div ref={refs[0]} className="b-mt2">
        <Checkbox
          checked={setting.isRecommended}
          onChange={() => update('isRecommended', (p) => !p)}
          disabled={isDisabled}
          label="Recommended"
        />
      </div>
    </Setting.Row>
  );
};

export default CategoryRow;
