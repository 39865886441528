import AppStoreAppsService from './service/app-store-apps-service';

const initialState = {
  id: '',
  name: '',
  icon: '',
  subtitle: '',
  description: '',
  publisher: '', // item.data.author
  supportedDeviceFamilies: {
    ipad: true, // item.device_families.includes('ipad')
    iphone: true, // item.device_families.includes('iphone')
    ipod: true, // item.device_families.includes('ipod')
    mac: true, // item.device_families.includes('mac')
    tv: true, // item.device_families.includes('tv')
    watch: true, // item.device_families.includes('watch')
    realityDevice: true, // item.device_families.includes('realityDevice')
  },
  baseDeviceFamilies: {
    ipad: true, // item.device_families.includes('ipad')
    iphone: true, // item.device_families.includes('iphone')
    ipod: true, // item.device_families.includes('ipod')
    mac: true, // item.device_families.includes('mac')
    tv: true, // item.device_families.includes('tv')
    watch: true, // item.device_families.includes('watch')
    realityDevice: true, // item.device_families.includes('realityDevice')
  },
  osRequirements: [], // only one field??? item.data.minimum_os_version
  isActive: true,
  isSalable: true, // item.is_salable
  selectedBlueprints: [],
  excludedBlueprints: [],
  isAllBlueprints: false,

  supportsRules: true,

  // only if supported device families includes 'mac', 'ipad' or 'iphone'
  installation: {
    type: AppStoreAppsService.installationTypes.CONTINUOUSLY_ENFORCE, // item.data.install_enforcement  oneOf('no_enforcement', 'continuously_enforce')
  },

  // only if supported device families includes 'mac', 'ipad' or 'iphone'
  // item.data.self_service_data
  selfService: {
    isEnabled: false, // false => item.data.self_service_data === undefined
    category: '', // item.data.self_service_data.category
    isRecommended: false, // item.data.self_service_data.isRecommended
    displayName: null, // item.data.self_service_data.displayName
    shortDescription: null, // item.data.self_service_data.description
    longDescription: null, // item.data.self_service_data.bodyText
    isRequireRead: false, // item.data.self_service_data.mustViewAgreement
    iconUrl: null, // item.data.self_service_data.iconUrl
    iconFile: null,
    size: 0, // item.data.file_size
    version: '', // item.data.version.display
    displayInfoBtn: false, // ?? item.data.self_service_data.displayInfoBtn ??
    infoBtnText: '', // ?? item.data.self_service_data.infoBtnText ??
    infoBtnUrl: '', // ?? item.data.self_service_data.infoBtnUrl ??
  },
  licenses: {
    used: 0, // item.used_licenses
    total: 5, // item.data.num_licenses
  },
  options: {
    // if currentCompany.is_vpp_auto_update_apps
    addToDockDuringInstall: false, // item.data.add_to_dock
    // if runs_on_mac
    isSkipAutoUpdate: false, // item.is_skip_auto_update
  },
  returnToService: {
    // if return to service is enabled
    isReturnToServiceEnabled: false, // item.data.is_return_to_service_enabled

    // wifi profile
    corporateWifiProfileId: '', // item.data.corporate_wifi_profile_id
  },
  // only if supported device families includes 'ipad', 'iphone', 'ipod', or 'tv'
  // one field item.data.configuration
  configuration: {
    value: '',
  },

  oktaDeviceTrust: {
    isEnabled: false,
  },

  // TODO: comment back in once splint-msdc-microsoft-authenticator-li flag is obsolete
  // msDeviceCompliance: {
  //   isEnabled: false,
  // },
};

export default initialState;
