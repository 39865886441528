import { createReducer } from 'redux-create-reducer';
import {
  USER_INTEGRATION_ACCESS_REVOKE,
  USER_INTEGRATION_CLEAR_USER,
  USER_INTEGRATION_CLEAR_USERS,
  USER_INTEGRATION_FETCHED,
  USER_INTEGRATION_FORCE_UPDATE,
  USER_INTEGRATION_REMOVE_USERS,
  USER_INTEGRATION_USERS_COUNTS_FETCHED,
  USER_INTEGRATION_USERS_FETCHED,
  USER_INTEGRATION_USER_FETCHED,
} from '../_actions/gSuite';

const initialState = {
  gSuiteAccountEmail: '',
  gSuiteSyncedAt: null,
  gSuiteUsers: [],
  currentGSuiteUser: null,
  forceUpdate: false,
  counts: {},
  type: null,
};

export default createReducer(initialState, {
  [USER_INTEGRATION_FETCHED](state, action) {
    return {
      ...state,
      // The following 3 will always be null (because of new API), but leaving
      // here...
      gSuiteAccountEmail: action.payload.email,
      gSuiteSyncedAt: action.payload.synced_at,
      type: action.payload.type,
      // New API with multiple integrations.
      userIntegrations: action.payload.results,
    };
  },
  [USER_INTEGRATION_ACCESS_REVOKE](state) {
    return {
      ...state,
      gSuiteAccountEmail: '',
      type: null,
    };
  },
  [USER_INTEGRATION_USERS_FETCHED](state, action) {
    return {
      ...state,
      ...action.payload,
    };
  },
  [USER_INTEGRATION_USER_FETCHED](state, action) {
    return {
      ...state,
      ...action.payload,
    };
  },
  [USER_INTEGRATION_CLEAR_USER](state) {
    return {
      ...state,
      currentGSuiteUser: null,
    };
  },
  [USER_INTEGRATION_CLEAR_USERS](state) {
    return {
      ...state,
      gSuiteUsers: [],
    };
  },
  [USER_INTEGRATION_USERS_COUNTS_FETCHED](state, action) {
    return {
      ...state,
      counts: action.payload,
    };
  },
  [USER_INTEGRATION_FORCE_UPDATE](state, action) {
    return {
      ...state,
      forceUpdate: action.payload,
    };
  },
  [USER_INTEGRATION_REMOVE_USERS](state, action) {
    const idsToRemove = (action.payload.usersToRemove || []).map(
      (user) => user.id,
    );
    const updatedUsers = [...state.gSuiteUsers].filter(
      (user) => !idsToRemove.includes(user.id),
    );

    return {
      ...state,
      gSuiteUsers: updatedUsers,
    };
  },
});
