import securityConfigs from 'features/library-items/library/library-item-configurations/items/security';
import featureFlags from 'src/config/feature-flags';
import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';
import { PostureValue } from '../avert.types';

const FF_END_USER_NOTIFICATION = 'edr_120123_end-user-notification-avert-li';
const FF_BEHAVIORAL_DETECTIONS = 'edr_083024_behavioral-detections-avert-li';

async function transformFromApi(apiData) {
  const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
  const excludedBlueprints = await getAllBlueprintOptions(
    apiData.excluded_blueprints,
  );

  const result = {
    id: apiData.id,
    name: apiData.name,
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints: apiData.is_all_blueprints,
    isActive: apiData.active,
    general: {
      pup_posture: apiData.data?.general.pup_posture,
      malware_posture: apiData.data?.general.malware_posture,
      ...(featureFlags.getFlag(FF_BEHAVIORAL_DETECTIONS) && {
        process_mal_posture:
          apiData.data?.general.process_mal_posture || PostureValue.DETECT,
      }),
    },
    ...(featureFlags.getFlag(FF_BEHAVIORAL_DETECTIONS) && {
      processMonitoringEnabled:
        apiData.data?.process_monitoring_enabled ?? true,
    }),
    allowBlock: {
      events: apiData.data?.allow_block.events,
    },
    unknownFileUpload: {
      file_upload_enabled:
        apiData.data?.unknown_file_upload?.file_upload_enabled,
    },
    ...(featureFlags.getFlag(FF_END_USER_NOTIFICATION) && {
      endUserNotifications: {
        end_user_notifications_enabled:
          apiData.data?.end_user_notifications
            ?.end_user_notifications_enabled || false,
      },
    }),
  };

  return {
    ...apiData,
    data: result,
  };
}

function transformToApi(model) {
  const toSend = {
    name: model.name,
    active: model.isActive,
    type: securityConfigs.Avert.type,
    blueprints: model.selectedBlueprints.map((b) => b.value),
    is_all_blueprints: model.isAllBlueprints,
    data: {
      general: {
        pup_posture: model.general.pup_posture,
        malware_posture: model.general.malware_posture,
        ...(featureFlags.getFlag(FF_BEHAVIORAL_DETECTIONS) && {
          process_mal_posture: model.general.process_mal_posture,
        }),
      },
      ...(featureFlags.getFlag(FF_BEHAVIORAL_DETECTIONS) && {
        process_monitoring_enabled: model.processMonitoringEnabled,
      }),
      allow_block: {
        events: model.allowBlock.events.map((i) => ({
          id: i.id,
          name: i.name,
          details: i.details,
          event_type: i.event_type,
          item_type: i.item_type,
        })),
      },
      unknown_file_upload: {
        file_upload_enabled: model.unknownFileUpload.file_upload_enabled,
      },
      ...(featureFlags.getFlag(FF_END_USER_NOTIFICATION) && {
        end_user_notifications: {
          end_user_notifications_enabled:
            model.endUserNotifications.end_user_notifications_enabled,
        },
      }),
    },
  };

  if (model.skip_blueprint_conflict) {
    toSend.skip_blueprint_conflict = model.skip_blueprint_conflict;
    toSend.reassign_lib_item_to_bp = model.skip_blueprint_conflict;
  }

  return toSend;
}

export { transformToApi, transformFromApi };
