export const linkEvent = (history, url) => (e) => {
  if (e.metaKey || e.ctrlKey) {
    e.preventDefault();
    e.stopPropagation();
    window.open(url);
    return;
  }
  history.push(url, { previousPage: history.location.pathname });
};

export const getOptionsFromObject = (obj) =>
  Object.entries(obj).map(([value, label]) => ({ value, label }));
