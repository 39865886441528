import { Button } from '@kandji-inc/bumblebee';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { H2, Text } from '../Typography';
import { StyledModalWrapper } from './StyledModalWrapper';
import { ButtonsWrapper } from './elements';

const Wrapper = styled.section`
  min-height: 150px;
  min-width: 350px;
  display: flex;
  flex-direction: column;
  padding: 30px 50px;
  justify-content: space-between;
  align-items: center;
`;

const CancelButton = styled(Button)`
  margin-right: 15px;
`;

const Title = styled(H2)`
  align-self: self-start;
`;

const Caption = styled(Text)`
  padding-top: 25px;
`;

const SimpleDialog = ({
  onHide,
  text,
  caption,
  cancelText,
  cancelAction,
  effectText,
  effectAction,
}) => {
  const [isLoading, setLoading] = useState(false);
  return (
    <StyledModalWrapper onHide={onHide}>
      <Wrapper>
        <Title>{text}</Title>
        {caption && <Caption>{caption}</Caption>}
        <ButtonsWrapper>
          <CancelButton
            type="button"
            onClick={cancelAction || onHide}
            kind="outline"
          >
            {cancelText}
          </CancelButton>
          <Button
            type="button"
            disabled={isLoading}
            icon={isLoading ? 'arrows-rotate' : null}
            onClick={() => {
              setLoading(true);
              effectAction()
                .then(() => setLoading(false))
                .then(() => onHide());
            }}
          >
            {effectText}
          </Button>
        </ButtonsWrapper>
      </Wrapper>
    </StyledModalWrapper>
  );
};

SimpleDialog.propTypes = {
  onHide: PropTypes.func.isRequired,
  text: PropTypes.string,
  caption: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  cancelText: PropTypes.string,
  cancelAction: PropTypes.func,
  effectText: PropTypes.string,
  effectAction: PropTypes.func,
};

SimpleDialog.defaultProps = {
  text: 'Are you sure?',
  caption: null,
  cancelText: 'Cancel',
  cancelAction: null,
  effectText: 'Delete',
  effectAction: null,
};

export default SimpleDialog;
